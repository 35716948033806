import(/* webpackMode: "eager" */ "/vercel/path0/apps/dapp/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.38.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["useRollbar","useRollbarPerson","useRollbarConfiguration"] */ "/vercel/path0/packages/rollbar-client/src/client/hooks.ts");
;
import(/* webpackMode: "eager", webpackExports: ["RollbarProvider"] */ "/vercel/path0/packages/rollbar-client/src/client/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/utils/src/hooks/use-boolean/use-boolean.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useCountdown"] */ "/vercel/path0/packages/utils/src/hooks/use-countdown/use-countdown.tsx");
